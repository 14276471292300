import { createSlice } from '@reduxjs/toolkit'
import data from './buildData'



export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        language: 'es',
        banner: data.es_banner,
        intro: data.es_intro,
        aboutus: data.es_aboutus,
        investmentOptions: data.es_investmentOptions,
        process: data.es_process,
        branches: data.es_branches,
        blog: data.es_blog,
        blogcategory: data.es_blogcategory,
        services: data.es_services,
        consumerservices: data.es_consumerservices,
        twitterfeed: data.es_twitterfeed,
        accounttypes: data.es_accounttypes,
        internationalwiretransfer: data.es_internationalwiretransfer,
        contactinfo: data.es_contactinfo,

        countries: [],
        states: [],
        cities: [],
        sessionTime: 300,//900,// segundos

    },
    reducers: {
        changeLanguageAction: (state, action) => {
            return {
                ...state,
                language: action.payload,
                banner: data[`${action.payload}_banner`],
                intro: data[`${action.payload}_intro`],
                aboutus: data[`${action.payload}_aboutus`],
                branches: data[`${action.payload}_branches`],
                blog: data[`${action.payload}_blog`],
                services: data[`${action.payload}_services`],
                consumerservices: data[`${action.payload}_consumerservices`],
                process: data[`${action.payload}_process`],
                investmentOptions: data[`${action.payload}_investmentOptions`],
                twitterfeed: data[`${action.payload}_twitterfeed`],
                accounttypes: data[`${action.payload}_accounttypes`],
                internationalwiretransfer: data[`${action.payload}_internationalwiretransfer`],
                contactinfo: data[`${action.payload}_contactinfo`],
                blogcategory: data[`${action.payload}_blogcategory`]
            }
        },
        setCountries: (state, action) => {
            return {
                ...state,
                countries: action.payload,
                states: [],
                cities: [],
            }
        },
        setStates: (state, action) => {
            return {
                ...state,
                states: action.payload,
                cities: [],
            }
        },
        setCities: (state, action) => {
            return {
                ...state,
                cities: action.payload
            }
        },

    },
})

// Action creators are generated for each case reducer function
export const { changeLanguageAction, setCountries, setStates, setCities } = dataSlice.actions

export default dataSlice.reducer