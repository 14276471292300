import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { enumUserRoles } from '../constants.js';

import Error from "../components/pages/Error";

function PrivateRoute({ component: Component, role, ...rest }) {
    const { isLogin, user } = useSelector((state) => state.user)
  
    return (
      <Route
        {...rest}
        render={(props) =>
            isLogin && role.indexOf(user?.role) > -1 ? <Component {...props} /> : <Error/>
        }
      />
    );
  }


export default PrivateRoute;
