import { createSlice } from '@reduxjs/toolkit'

const initialState= {
    isLogin : false,
    loginDate : null,
    jwtToken: null,
    sessionData: null,
    user: {},
    users:[]
 }

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            return {
                ...state,
                isLogin: true,
                jwtToken: action.payload.access_token,
                sessionData: action.payload.secureSessionData,
                loginDate: (new Date()).getTime(),
                user: action.payload.user,
            }
        },
        updateToken: (state, action) => {
            return {
                ...state,
                jwtToken: action.payload,
                loginDate: (new Date()).getTime(),
            }
        },
        logout: (state, action) => {
            return initialState
        },
        setUsers: (state, action) => {
            return {
                ...state,
                users: action.payload
            }
            
        },
        setUser: (state, action) => {
            return {
                ...state,
                user: action.payload
            }
            
        },
    },
})

// Action creators are generated for each case reducer function
export const { login,  logout, setUsers, setUser, updateToken } = userSlice.actions

export default userSlice.reducer